import {
  AvatarIcon,
  InputSearch,
  LogoBeta,
  LogoSmallBeta,
  NotificationIcon,
  OverlayNotification,
  SecondaryButton,
  OverlayOptionUser,
} from "../..";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/userAuth";
import { useEffect, useState, useRef } from "react";
import { User } from "../../../types";
import { CSSTransition } from "react-transition-group";
import { useMediaQuery } from "react-responsive";

interface HeaderProps {
  search?: boolean;
}

export const MainHeader: React.FC<HeaderProps> = ({ search = false }) => {
  const { user: authUser } = useAuth();
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const [showOverlayNotification, setShowOverlayNotification] = useState(false);
  const [showOverlayOptionUser, setShowOverlayOptionUser] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const overlayNotificationRef = useRef<HTMLDivElement>(null);
  const overlayOptionUserRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  const userLogo = authUser?.logo;
  const navigate = useNavigate();

  const navigateTosign = () => {
    navigate(`/sign`);
  };

  const navigateToInscription = () => {
    navigate(`/inscription`);
  };

  const navigateToSearch = () => {
    navigate(`/search`, { state: { fromHeader: true } });
  };

  const isMediumScreen = useMediaQuery({
    query: "(min-width: 745px) and (max-width: 1156px)",
  });

  const toggleOverlayNotification = () => {
    if (!disableClick) {
      setShowOverlayNotification((prev) => !prev);
      setShowOverlayOptionUser(false);
    }
  };

  const toggleOverlayOptionUser = () => {
    if (!disableClick) {
      setShowOverlayOptionUser((prev) => !prev);
      setShowOverlayNotification(false);
    }
  };

  const closeOverlayNotification = () => {
    setShowOverlayNotification(false);
    setDisableClick(true);
    setTimeout(() => setDisableClick(false), 100);
  };

  const closeOverlayOptionUser = () => {
    setShowOverlayOptionUser(false);
    setDisableClick(true);
    setTimeout(() => setDisableClick(false), 100);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        overlayNotificationRef.current &&
        !overlayNotificationRef.current.contains(event.target as Node)
      ) {
        closeOverlayNotification();
      }
      if (
        overlayOptionUserRef.current &&
        !overlayOptionUserRef.current.contains(event.target as Node)
      ) {
        closeOverlayOptionUser();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <header className="fixed left-0 top-0 z-[1000] flex w-full justify-center ">
      <nav
        className={`${isMediumScreen ? "pl-8" : "pl-5"} mb-0 mt-0 flex h-[58px] w-full items-center justify-between border-b border-b-[rgba(198,182,213,0.25)] bg-[#2C1A3F] pr-2 min-max:w-[1280px]`}
      >
        {isMediumScreen ? <LogoSmallBeta /> : <LogoBeta />}
        <InputSearch
          showLogoSection={true}
          placeholder="Rechercher sur TalkPlay"
          size={`${search && "hidden"} h-[36px] min-w-[50%] !w-auto`}
          onClick={navigateToSearch}
        />
        {!auth.user ? (
          <div className="flex items-center items-center gap-x-2">
            <SecondaryButton
              label="Connexion"
              transparent={true}
              onClick={navigateTosign}
              size="max-950:w-[110px]"
            />
            <SecondaryButton
              label="Inscription"
              onClick={navigateToInscription}
              size="max-950:w-[110px]"
            />
          </div>
        ) : (
          <div className="relative flex items-center gap-x-4">
            {/* <NotificationIcon onClick={toggleOverlayNotification} /> */}
            <div onClick={toggleOverlayOptionUser}>
              <AvatarIcon
                logo={userLogo}
                className={`avatar-xs cursor-pointer border border-[#C6B6D5] ${disableClick ? "cursor-not-allowed" : "cursor-pointer"}`}
              />
            </div>
            <CSSTransition
              in={showOverlayNotification}
              timeout={300}
              classNames="overlay"
              unmountOnExit
            >
              <div
                className="absolute right-8 top-[46px] z-[100]"
                ref={overlayNotificationRef}
              >
                <OverlayNotification onClose={closeOverlayNotification} />
              </div>
            </CSSTransition>
            <CSSTransition
              in={showOverlayOptionUser}
              timeout={300}
              classNames="overlay"
              unmountOnExit
            >
              <div
                className="absolute right-8 top-[46px] z-[100]"
                ref={overlayOptionUserRef}
              >
                <OverlayOptionUser onClose={closeOverlayOptionUser} />
              </div>
            </CSSTransition>
          </div>
        )}
      </nav>
    </header>
  );
};
