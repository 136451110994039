import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "..";

export const OverlayUnauthenticated = () => {
  const navigate = useNavigate();

  return (
    <div
      className="z-10 flex h-[135px] flex-col items-center justify-center gap-y-4 self-stretch rounded-t-3xl border-2 border-transparent px-5 py-6 opacity-90 shadow-[0_2px_4px_rgba(0,0,0,0.25)] backdrop-blur-[6.5px] max-mobile:sticky max-mobile:bottom-0 max-mobile:left-0 max-mobile:right-0 max-mobile:w-full"
      style={{
        background:
          "linear-gradient(293deg, #402C48 0.12%, #9552A9 117.69%) padding-box, linear-gradient(to bottom right, rgba(198, 182, 213, 0.91), #402C48 40%) border-box",
      }}
    >
      <div className="flex flex-col justify-center gap-y-1 self-stretch">
        <p className="h2-mobile self-stretch text-center">
          Inscris toi sur TalkPlay
        </p>
        <p className="paragraph-m-400 self-stretch text-center">
          Et rejoins dès maintenant la nouvelle communauté gaming
        </p>
      </div>
      <div className="flex gap-x-5">
        <SecondaryButton
          transparent={true}
          label="Se connecter"
          onClick={() => navigate("/sign")}
        />
        <SecondaryButton
          label="Créer un compte"
          onClick={() => navigate("/inscription")}
        />
      </div>
    </div>
  );
};
