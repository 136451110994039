import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import {
  AvatarIcon,
  ButtonSimple,
  CommunityFillIcon,
  CommunityStrokeIcon,
  DiscordIcon,
  Friend,
  GroupFillIcon,
  GroupStrokeIcon,
  HomeFillIcon,
  HomeStrokeIcon,
  MessageFillIcon,
  MessageStrokeIcon,
} from "../";
import { useAuth } from "../../providers/userAuth";
import { fetchFriends } from "../../helpers/fetchUser";
import { Friend as FriendType } from "../../types";

type SidebarProps = {
  size?: "normal" | "small";
};

export const Sidebar: React.FC<SidebarProps> = ({ size = "normal" }) => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isSmall = size === "small";

  const [friendsData, setFriendsData] = useState<FriendType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const hasFetchedFriends = useRef(false);
  const currentYear = new Date().getFullYear();

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };
  useEffect(() => {
    const getFriends = async () => {
      if (user?.username && !hasFetchedFriends.current) {
        try {
          const friends = await fetchFriends(encodeUsername(user.username))();
          setFriendsData(friends.slice(0, 5));
          hasFetchedFriends.current = true;
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      } else if (!user?.username) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    const delayFetchFriends = setTimeout(() => {
      getFriends();
    }, 300);

    return () => clearTimeout(delayFetchFriends);
  }, [user]);

  const getLinkClasses = (to: string) =>
    location.pathname === to
      ? "bg-[rgba(245,235,255,0.10)] border-r-[2px] border-r-primary-500 rounded-l-lg"
      : "hover:bg-[rgba(245,235,255,0.20)] hover:opacity-100 rounded-l-lg";

  const getLinkClassesStartWith = (to: string) =>
    location.pathname.startsWith(to)
      ? "bg-[rgba(245,235,255,0.10)] border-r-[2px] border-r-primary-500 rounded-l-lg"
      : "hover:bg-[rgba(245,235,255,0.20)] hover:opacity-100 rounded-l-lg";

  const friendsList =
    friendsData.map((friend, index) => (
      <Friend
        key={index}
        username={friend.username}
        logo={friend.logo}
        size={size}
        classname="hover:bg-[rgba(245,235,255,0.20)] rounded-lg py-[3px]"
      />
    )) || [];

  const handleClick = () => {
    window.open(
      "https://discord.gg/bfYqH8jB8W",
      "_blank",
      "noopener,noreferrer",
    );
  };

  const handleNewPostClick = () => {
    navigate("/createtalk");
  };

  const navigateTerms = () => {
    navigate(`/legal/terms`);
  };

  const navigateGuidelines = () => {
    navigate(`/legal/guidelines`);
  };

  const navigatePrivacy = () => {
    navigate(`/legal/privacy`);
  };

  return (
    <div
      className={`flex hidden ${
        isSmall ? "w-[110px]" : "w-[288px]"
      } styled-scrollbars fixed sticky top-[58px] isolate box-border max-h-[calc(100vh-60px)] overflow-y-auto overflow-x-hidden border-r border-solid border-r-[rgba(198,182,213,0.25)] bg-[rgba(255,255,255,0.05)] pb-1 backdrop-blur-[7px] min-745:block `}
    >
      <div className="sticky box-border block pl-1 pt-6">
        <div className="box-border flex min-h-[calc(100vh-100px)] w-full flex-col justify-between">
          <div className="flex flex-col items-center gap-y-8">
            <div className="flex flex-col items-start gap-y-2 self-stretch">
              <div
                className={`flex flex-col ${
                  isSmall ? "justify-center" : "items-start"
                } self-stretch`}
              >
                <Link
                  to="/home"
                  className={`flex ${
                    isSmall && "justify-center"
                  } ${getLinkClasses("/")} items-center gap-x-2 self-stretch px-2`}
                >
                  <div className="flex h-[52px] w-[52px] items-center justify-center">
                    {location.pathname === "/" ? (
                      <HomeFillIcon />
                    ) : (
                      <HomeStrokeIcon />
                    )}
                  </div>
                  {!isSmall && <p className="label-m-400">Accueil</p>}
                </Link>
                {user?.username && (
                  <Link
                    to={`/user/${encodeUsername(user.username)}`}
                    className={`flex ${
                      isSmall && "justify-center"
                    } ${getLinkClasses(`/user/${user.username}`)} items-center gap-x-2 self-stretch px-2`}
                  >
                    <div className="flex h-[52px] w-[52px] items-center justify-center">
                      <AvatarIcon
                        logo={user?.logo}
                        className="flex h-6 w-6 items-center rounded-xl"
                      />
                    </div>
                    {!isSmall && <p className="label-m-400">{user.username}</p>}
                  </Link>
                )}
                {user?.username && (
                  <Link
                    to={`/messages`}
                    className={`flex ${
                      isSmall && "justify-center"
                    } ${getLinkClassesStartWith(`/messages`)} items-center gap-x-2 self-stretch px-2`}
                  >
                    <div className="flex h-[52px] w-[52px] items-center justify-center">
                      {location.pathname.startsWith("/messages") ? (
                        <MessageFillIcon />
                      ) : (
                        <MessageStrokeIcon />
                      )}
                    </div>
                    {!isSmall && <p className="label-m-400">Messagerie</p>}
                  </Link>
                )}
                {user?.username && (
                  <Link
                    to="/managefriends"
                    className={`flex ${
                      isSmall && "justify-center"
                    } ${getLinkClasses(`/managefriends`)} items-center gap-x-2 self-stretch px-2`}
                  >
                    <div className="flex h-[52px] w-[52px] items-center justify-center">
                      {location.pathname === "/managefriends" ? (
                        <GroupFillIcon />
                      ) : (
                        <GroupStrokeIcon />
                      )}
                    </div>
                    {!isSmall && <p className="label-m-400">Amis</p>}
                  </Link>
                )}
                <Link
                  to="/community"
                  className={`flex ${
                    isSmall && "justify-center"
                  } ${getLinkClasses(`/community`)} items-center gap-x-2 self-stretch px-2`}
                >
                  <div className="flex h-[52px] w-[52px] items-center justify-center">
                    {location.pathname === "/community" ? (
                      <CommunityFillIcon />
                    ) : (
                      <CommunityStrokeIcon />
                    )}
                  </div>
                  {!isSmall && <p className="label-m-400">Communauté</p>}
                </Link>
              </div>
              {user?.username && (
                <div className="flex w-full flex-col self-stretch px-2">
                  <ButtonSimple
                    label={isSmall ? "" : "Nouvelle publication"}
                    plusFonction={true}
                    onClick={handleNewPostClick}
                    classname="min-1157:!justify-start min-1157:!px-[9px] min-1157:!gap-x-[18px]"
                  />
                </div>
              )}
            </div>
            {user?.username && friendsList.length > 0 && (
              <div className="flex flex-col items-start gap-y-1 self-stretch pb-4">
                <span className="mb-1 h-[1px] w-[95%] bg-[rgba(198,182,213,0.25)]"></span>
                <div
                  className={`flex ${
                    isSmall ? "justify-center" : "items-start justify-between"
                  } w-full px-5 pb-1`}
                >
                  <p className="label-m-400">
                    {isSmall ? "Amis" : "Amis TalkPlay"}
                  </p>
                  {!isSmall && (
                    <Link
                      to="/managefriends/friends"
                      className="link-s cursor-pointer opacity-70 hover:opacity-100"
                    >
                      Voir tous
                    </Link>
                  )}
                </div>
                {friendsList}
              </div>
            )}
          </div>
          <div className="flex w-full flex-col items-center gap-y-1 max-pc:hidden">
            <span className="mb-2 h-[1px] w-[95%] bg-[rgba(198,182,213,0.25)]"></span>
            <div
              className="cursor-pointer opacity-50 hover:opacity-100"
              onClick={handleClick}
            >
              <DiscordIcon />
            </div>
            <div className="flex w-full flex-wrap content-start items-start justify-center gap-x-2 gap-y-1">
              <p
                className="label-s-400 cursor-pointer opacity-50 hover:underline hover:opacity-100"
                onClick={navigateTerms}
              >
                Termes du service
              </p>
              <p
                className="label-s-400 cursor-pointer opacity-50 hover:underline hover:opacity-100"
                onClick={navigatePrivacy}
              >
                Politique de confidentialité
              </p>
              <p
                className="label-s-400 cursor-pointer opacity-50 hover:underline hover:opacity-100"
                onClick={navigateGuidelines}
              >
                Normes communautaires
              </p>
            </div>
            <p className="label-s-400 opacity-50">
              TalkPlay © {currentYear}. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
