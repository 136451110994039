import { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  LogoBeta,
  LinkedInIcon,
  CorrectCheck,
  FalseCheck,
  GameCoverColumn,
} from "../../components";
import LandingChat from "../../assets/Landing_Chat.png";
import LandingAchievement from "../../assets/Landing_Achievement.png";
import LandingGamepad from "../../assets/Landing_Gamepad.png";
import BG_PC from "../../assets/Landing_BG_PC.png";
import BG_TB from "../../assets/Landing_BG_TB.png";
import EpicGames from "../../assets/EpicGames.png";
import Steam from "../../assets/Steam.png";
import Playstation from "../../assets/Playstation.png";
import Supercell from "../../assets/Supercell.png";
import RiotGames from "../../assets/RiotGames.png";
import ConnectGamesMobile from "../../assets/ConnectGamesMobile.png";
import ConnectGamesDesktop from "../../assets/ConnectGamesDesktop.png";
import ConnectGamesDesktop2 from "../../assets/ConnectGamesDesktop2.png";
import LandingCoc from "../../assets/Landing_CoCPlay.png";
import LandingCS2 from "../../assets/Landing_CS2Play.png";
import LandingLol from "../../assets/Landing_LolPlay.jpg";
import LandingSearch from "../../assets/Landing_Search.png";
import GameplayBG from "../../assets/Gameplay_BG.png";
import CoverCoC from "../../assets/CoverCoC.png";
import CoverFortnite from "../../assets/CoverFortnite.png";
import CoverLoL from "../../assets/CoverLoL.png";
import CoverValorant from "../../assets/CoverValorant.png";
import CoverRL from "../../assets/CoverRL.png";
import BottomBanner from "../../assets/BottomBanner.png";
import { preregister } from "../../helpers/Auth";

export const LandingPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<React.ReactNode>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showRedBorder, setShowRedBorder] = useState<boolean>(false);

  const emailRef = useRef("");
  const emailInputRef = useRef<HTMLInputElement>(null);

  const navigateTerms = () => {
    navigate(`/legal/terms`);
  };

  const navigatePrivacy = () => {
    navigate(`/legal/privacy`);
  };

  const [carouselImages, setCarouselImages] = useState([
    LandingCoc, // Left
    LandingCS2, // Center
    LandingLol, // Right
  ]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    const previousEmail = emailRef.current;

    setEmail(newEmail);
    setShowEmailError(false);
    setShowRedBorder(false);
    setErrorMessage(null); // Clear error message
    setSuccessMessage(null); // Clear success message when user starts typing a new email

    const isAdding = newEmail.length > previousEmail.length;

    emailRef.current = newEmail;

    if (!isAdding || newEmail.includes(".")) {
      const isValid = validateEmail(newEmail);
      setIsEmailValid(isValid);
    } else {
      setIsEmailValid(null);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePreRegister = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevents the default form submission behavior

    if (!isEmailValid) {
      setShowEmailError(true);
      setErrorMessage("Veuillez entrer une adresse email valide.");
      setSuccessMessage(null); // Clear success message if there's an error
      setIsEmailValid(false);
      setShowRedBorder(true);
      return;
    }

    try {
      // Call the preregister function with the email
      const response = await preregister(email)();

      // Check the response message or status
      if (
        response.status !== 200 ||
        response.data.message === "Email already exists"
      ) {
        setErrorMessage("Cet email est déjà préinscrit.");
        setSuccessMessage(null); // Clear success message if there's an error
      } else {
        // If the submission is successful
        setSuccessMessage(
          <>
            Votre préinscription a été prise en compte !<br />
            Un mail de confirmation a été envoyé.
          </>,
        );
        setErrorMessage(null);
        setEmail("");
        setIsEmailValid(null);
        setShowRedBorder(false);
      }
    } catch (error) {
      // Handle errors
      setErrorMessage("Une erreur est survenue. Veuillez réessayer plus tard.");
      setSuccessMessage(null); // Clear success message if there's an error
    }
  };

  const scrollToEmailInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.scrollIntoView({ behavior: "smooth" });
      emailInputRef.current.focus(); // Optional: focus the input
    }
  };

  const handleCarouselClick = (clickedImage) => {
    if (carouselImages[1] === clickedImage) return;

    const newOrder = carouselImages.filter((img) => img !== clickedImage);
    setCarouselImages([newOrder[0], clickedImage, newOrder[1]]);
  };

  return (
    <div className="relative flex h-full w-full flex-col overflow-x-hidden bg-[#280C31]">
      {/* Background Image */}
      <img
        src={BG_PC}
        alt="BG icon"
        className="absolute left-0 top-0 z-0 h-full max-h-[1200px] w-full object-cover max-825:hidden"
      />
      <img
        src={BG_TB}
        alt="BG icon"
        className="absolute left-0 top-0 z-0 h-full max-h-[1200px] w-full object-cover min-825:hidden"
      />
      {/* Main Content */}
      <div className="relative z-10 flex flex-grow flex-col">
        {/* Header */}
        <header className="z-10 flex w-full justify-between px-4 py-4 min-825:px-[88px]">
          <LogoBeta clickable={false} />
        </header>

        {/* Background Graphics */}
        <div className="absolute inset-0 z-0">
          <img
            src={LandingAchievement}
            alt="Achievement icon"
            className="absolute right-0 top-[600px] z-10 h-[200px] min-1050:h-[450px] sm:h-[250px] md:top-[500px] md:h-[300px] lg:h-[350px] xl:h-[400px]"
          />
          <img
            src={LandingGamepad}
            alt="Gamepad icon"
            className="absolute left-0 top-[300px] z-20 w-[100px] min-1050:w-[450px] sm:w-[220px] md:top-[350px] md:w-[300px] lg:w-[350px] xl:w-[400px]"
          />
        </div>

        {/* Main Section */}
        <main className="z-10 flex w-full flex-col items-center justify-center">
          <img
            src={LandingChat}
            alt="Chat icon"
            className="mb-8 h-[100px] min-1050:h-[215px] sm:h-[125px] md:h-[150px] lg:h-[175px] xl:h-[215px]"
          />

          <div className="flex w-full flex-col items-center justify-center gap-y-12 md:gap-y-12">
            <div className="flex w-full flex-col items-center justify-center gap-y-6">
              <h1 className="max-w-[80%] text-center font-russo text-[40px] font-normal leading-tight tracking-[-1.232px] text-white max-400:text-[34px] min-1050:text-[56px]">
                Préinscris-toi dès maintenant sur ton réseau social gaming !
              </h1>
            </div>

            {/* Form Section */}
            <form
              onSubmit={handlePreRegister}
              className="flex flex-col items-center justify-center gap-y-6"
            >
              <div className="relative flex w-full min-w-[350px] items-center">
                <input
                  type="email"
                  placeholder="steve@email.com"
                  value={email}
                  onChange={handleEmailChange}
                  ref={emailInputRef}
                  className={`h-16 w-full rounded-xl ${
                    showRedBorder ? "border-red" : "border-white"
                  } border bg-[rgba(245,235,255,0.15)] pb-1 pl-3 pr-10 text-xl text-white focus:border-transparent focus:outline-none focus:ring-2 focus:ring-[#6441A5]`}
                />
                {isEmailValid !== null && (
                  <div className="absolute right-2">
                    {isEmailValid ? <CorrectCheck /> : <FalseCheck />}
                  </div>
                )}
              </div>
              {errorMessage && (
                <p className="h3-mobile flex items-center gap-x-2 text-center text-red opacity-90">
                  {errorMessage}
                </p>
              )}

              {successMessage && (
                <p className="h3-mobile flex items-center gap-x-2 text-center text-green opacity-90">
                  {successMessage}
                </p>
              )}
              <button
                type="submit"
                className="flex h-14 w-auto cursor-pointer items-center justify-center rounded-[12px] bg-[#AA1638] px-4 py-[10px] backdrop-blur-[2px] transition-transform duration-300 hover:scale-105"
              >
                <p className="h3-mobile button-label text-xl text-white">
                  Réserve ton pseudo !
                </p>
              </button>
            </form>
          </div>
        </main>

        <div className="flex w-full items-center justify-center pt-[150px]"></div>

        {/* Section de Connexion */}
        <section className="flex w-full flex-col items-center justify-center gap-y-10 pt-[500px]">
          <img
            src={GameplayBG}
            alt="Background Gameplay"
            className="absolute left-0 top-0 z-0 mt-[1000px] aspect-square w-full object-cover"
          />
          <div className="z-10 flex max-w-[90%] flex-col justify-center gap-y-2 min-1050:max-w-[60%]">
            <p className="paragraph-s-500 text-center text-white">CONNECT</p>
            <div className="flex w-full flex-col items-center justify-center gap-y-4">
              <h2 className="text-shadow-custom text-center font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white">
                Centralise tes jeux et statistiques sur une seule plateforme
              </h2>
              <p className="paragraph-m-400 text-center text-white">
                Avec TalkPlay, synchronise tes jeux de différentes plateformes
                en un seul lieu. Construis ta bibliothèque personnalisée et
                accède facilement à tes statistiques pour les analyser et les
                partager avec tes amis. Rejoins la communauté pour une
                expérience de gaming enrichie et connectée.
              </p>
            </div>
          </div>
          {/* Section des logos des plateformes */}
          <div className="relative flex max-w-[90%] flex-wrap content-center items-center justify-center gap-6">
            <img src={Steam} alt="Steam icon" className="z-10 h-[60px]" />
            <img
              src={RiotGames}
              alt="Riot Games icon"
              className="z-30 h-[60px]"
            />
            <img
              src={Supercell}
              alt="Supercell icon"
              className="z-20 h-[60px]"
            />
            <img
              src={EpicGames}
              alt="Epic Games icon"
              className="z-40 h-[60px]"
            />
            <img
              src={Playstation}
              alt="Playstation icon"
              className="z-50 h-[60px]"
            />
          </div>
          {/* Section Connect Games Images */}
          <div className="relative flex items-center justify-center ">
            <img
              src={ConnectGamesMobile}
              alt="Connect Games Mobile"
              className="z-[100] h-[415px] transition-all duration-300 max-850:absolute max-850:left-1/2 max-850:top-[70%] max-850:block max-850:-translate-x-1/2 max-850:-translate-y-1/2 max-mobile:h-[315px]"
            />
            <img
              src={ConnectGamesDesktop}
              alt="Connect games Desktop"
              className="z-40 h-[300px] overflow-hidden object-cover max-400:hidden max-500:inset-0 sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px]"
            />
            <img
              src={ConnectGamesDesktop2}
              alt="Connect games Desktop"
              className="z-40 h-[300px] w-full overflow-hidden object-cover min-400:hidden"
            />
          </div>
        </section>

        {/* Section Partage tes gameplays */}
        <section className="relative flex w-full flex-col items-center justify-center gap-y-10 pt-[300px]">
          <div className="relative z-10 flex flex-col items-center justify-center gap-y-8">
            <div className="flex max-w-[90%] flex-col justify-center gap-y-2 min-1050:max-w-[55%]">
              <p className="paragraph-s-500 text-center text-white">PLAY</p>
              <div className="flex w-full flex-col items-center justify-center gap-y-4">
                <h2 className="text-shadow-custom text-center font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white">
                  Partage tes exploits et construis ta bibliothèque de gameplays
                  sur TalkPlay
                </h2>
                <p className="paragraph-m-400 text-center text-white">
                  Partage tes meilleures sessions de jeu sur TalkPlay ! Découvre
                  et compare les kills et combos de la communauté, et conserve
                  tous tes moments forts dans ta bibliothèque.
                </p>
              </div>
            </div>

            {/* Section des trois images */}
            <div className="flex flex-col items-center justify-center space-y-4 pt-10 md:flex-row md:space-x-4 md:space-y-0">
              {carouselImages.map((img, index) => (
                <div
                  key={img}
                  onClick={() => handleCarouselClick(img)}
                  className={`relative transform cursor-pointer transition-transform duration-700 ${
                    index === 1
                      ? "z-20 h-40 w-[100%] scale-110 shadow-lg md:h-80 md:w-1/3"
                      : "z-10 h-[120px] w-[100%] scale-100 opacity-80 md:h-60 md:w-1/4"
                  }`}
                >
                  <img
                    src={img}
                    alt={`Gameplay ${index + 1}`}
                    className="h-full w-full rounded-lg object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="z-10 flex w-screen flex-col items-center justify-center gap-y-10 pb-[300px] pt-[300px] ">
          <div className="flex flex-col items-center justify-center gap-y-8">
            <div className="flex max-w-[90%] flex-col justify-center gap-y-2 min-1050:max-w-[55%]">
              <p className="paragraph-s-500 text-center text-white">FIND</p>

              <div className="flex w-full flex-col items-center justify-center gap-y-4">
                <h2 className="text-shadow-custom text-center font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white">
                  Retrouve tes amis in-game et tes joueurs favoris, même s’ils
                  ne sont pas encore sur TalkPlay
                </h2>
                <p className="paragraph-m-400 text-center text-white">
                  Découvre à quoi jouent tes amis et n'importe quel joueur avec
                  qui tu as joué. Qu'il s'agisse du meilleur joueur mondial de
                  ton jeu ou simplement d'un joueur avec qui tu as partagé une
                  partie ! <br />
                  Il suffit juste d'avoir leur pseudo ou tag dans le jeu !
                </p>
              </div>
            </div>
            <div className="relative flex items-center justify-center">
              {/* Section pour les petits écrans : CoverCoC derrière LandingSearch */}
              <GameCoverColumn
                banner={CoverCoC}
                size="w-[250px] h-[375px]"
                isClickable={false}
                className="absolute left-1/2 top-1/2 z-0 mt-[200px] hidden -translate-x-1/2 -translate-y-1/2 transform max-850:block"
              />
              <img
                src={LandingSearch}
                alt="Connect Games Mobile"
                className="absolute left-1/2 top-1/2 z-10 mt-20 hidden h-[515px] -translate-x-1/2 -translate-y-1/2 transform transition-all duration-300 max-850:block max-mobile:h-[515px]"
              />
              {/* Section pour les grands écrans : les images côte à côte */}
              <div className="flex items-center justify-center gap-x-10 max-850:flex max-850:hidden">
                <GameCoverColumn
                  banner={CoverLoL}
                  size="w-[250px] h-[375px]"
                  isClickable={false}
                />
                <img
                  src={LandingSearch}
                  alt="Connect Games Mobile"
                  className="z-[100] h-[415px] transition-all duration-300"
                />
                <GameCoverColumn
                  banner={CoverCoC}
                  size="w-[250px] h-[375px]"
                  isClickable={false}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="flex w-full items-center justify-center max-1050:flex-col">
          <div className="flex w-[30%] flex-col items-start gap-y-6 max-1050:w-[80%] max-1050:items-center max-1050:justify-center">
            <div className="flex flex-col gap-y-2 max-1050:items-center">
              <p className="paragraph-s-500 text-left text-white">
                JOIN THE COMMUNITY
              </p>
              <h2 className="text-shadow-custom text-left font-poppins text-[32px] font-semibold tracking-[-0.704px] text-white max-1050:text-center">
                Toute ton expérience de gaming au même endroit avec TalkPlay...
                <br />
                et bien plus à venir !
              </h2>
            </div>
            <div
              className="flex h-12 w-full max-w-[380px] cursor-pointer items-center justify-center rounded-[12px] bg-[#B10F2E] px-4 py-[10px] backdrop-blur-[2px] transition-transform duration-300 hover:scale-105"
              onClick={scrollToEmailInput}
            >
              <p className="button-label text-white">PRÉINSCRIS-TOI</p>
            </div>
          </div>
          <img
            src={BottomBanner}
            alt="Connect Games Mobile"
            className="z-[100] h-full w-[50%] max-1050:w-[100%]"
          />
        </section>

        {/* Footer */}
        <footer className="z-[100] flex w-full flex-col bg-[#0C0C0C] px-[88px] pb-[58px] pt-12 max-1050:px-5">
          <div className="flex w-full justify-between max-650:gap-y-7 max-1050:flex-col max-1050:gap-y-10">
            <div className="flex w-[50%] gap-x-20 max-650:flex-col max-650:gap-y-7 max-1050:w-full min-1050:justify-between">
              <div className="flex flex-col gap-y-6 pb-2">
                <div className="flex flex-col items-start gap-y-1">
                  <h1 className="h1-mobile">TalkPlay</h1>
                  <p className="paragraph-m-400">
                    Connect games,
                    <br /> Find any player, Share gameplay.
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <p
                  className="link-s cursor-pointer hover:font-bold"
                  onClick={navigatePrivacy}
                >
                  Condition d’utilisation
                </p>
                <p
                  className="link-s cursor-pointer hover:font-bold"
                  onClick={navigateTerms}
                >
                  Termes
                </p>
              </div>
            </div>
            <div className="w-[30%] max-1050:w-full">
              <p className="paragraph-m-400">
                TalkPlay est une plateforme communautaire qui centralise
                l'expérience de gaming. Retrouve tes jeux et discussions en un
                seul lieu, crée ta bibliothèque pour suivre et partager des
                statistiques et gameplays. Continuellement enrichie de nouveaux
                jeux, TalkPlay dynamise et unit la communauté des gamers.
              </p>
            </div>
            <div className="min-1050:hidden">
              <a
                href="https://www.linkedin.com/company/talkplay"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
          <div className="max-1050:hidden">
            <a
              href="https://www.linkedin.com/company/talkplay"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};
