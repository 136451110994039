import { useNavigate } from "react-router-dom";
import { BackButton, LogoBeta } from "../../components";

export const CommunityGuidelines: React.FC = () => {
  const navigate = useNavigate();

  const navigateTerms = () => {
    navigate(`/legal/terms`);
  };

  const navigatePrivacy = () => {
    navigate(`/legal/privacy`);
  };
  return (
    <div className="flex max-h-[100svh] w-full flex-col gap-y-5 overflow-y-scroll bg-[#140029] px-5 pb-20 pt-10 min-745:px-10">
      <BackButton />
      <div className="relative flex w-full justify-center">
        <LogoBeta />
      </div>
      <h1 className="h1-mobile flex w-full justify-center">
        Normes Communautaires
      </h1>
      <p className="paragraph-s-400">
        Dernière mise à jour : 1er novembre 2024
      </p>

      <p className="paragraph-s-400">
        TalkPlay est un réseau social conçu pour les gamers et les acteurs du
        gaming. Notre objectif est de créer un espace où vous pouvez vous
        informer et vous exprimer en toute sécurité. Nous avons établi nos
        Normes Communautaires pour garantir que tous les utilisateurs puissent
        utiliser nos services de manière sécurisée et agréable. Ces normes
        doivent être respectées et font partie de notre Accord Utilisateur, qui
        inclut également nos{" "}
        <span
          className="paragraph-s-700 cursor-pointer underline"
          onClick={navigateTerms}
        >
          Termes du Service
        </span>{" "}
        et notre{" "}
        <span
          className="paragraph-s-700 cursor-pointer underline"
          onClick={navigatePrivacy}
        >
          Politique de Confidentialité
        </span>
        . Si vous n'êtes pas d'accord avec une partie de cet Accord, TalkPlay
        n'est peut-être pas fait pour vous.
      </p>

      <p className="paragraph-s-400">
        Nos Normes Communautaires visent à assurer le respect mutuel. En bref,
        ne faites pas aux autres ce que vous ne voudriez pas qu’on vous fasse.
        Voici quelques exemples pour clarifier ces normes :
      </p>

      <ul className="paragraph-s-400 list-inside list-disc">
        <li>
          <span className="paragraph-s-700">
            Intimidation, abus et harcèlement :
          </span>{" "}
          Il est interdit de partager du contenu destiné à intimider, abuser ou
          harceler des tiers, qu'il s'agisse d'autres utilisateurs ou de
          non-utilisateurs.
        </li>
        <li>
          <span className="paragraph-s-700">
            Exploitation sexuelle des enfants :
          </span>{" "}
          L’exploitation des enfants est formellement interdite, et TalkPlay
          applique une tolérance zéro envers tout contenu présentant ou
          encourageant l'exploitation sexuelle des enfants.
        </li>
        <li>
          <span className="paragraph-s-700">Contenu haineux :</span> Il est
          interdit de partager du contenu haineux envers des personnes ou des
          organisations.
        </li>
        <li>
          <span className="paragraph-s-700">Activité illégale :</span> Il est
          interdit de partager du contenu qui encourage ou entraîne des
          activités illégales.
        </li>
        <li>
          <span className="paragraph-s-700">Usurpation d’identité :</span> Il
          est interdit d’utiliser le pseudonyme d’autrui et de se faire passer
          pour.
        </li>
        <li>
          <span className="paragraph-s-700">Propriété intellectuelle :</span> Il
          est interdit de partager du contenu qui porte atteinte à la propriété
          intellectuelle d’autrui.
        </li>
        <li>
          <span className="paragraph-s-700">Nudité non-consentie :</span> La
          nudité non-consentie est formellement interdite, et TalkPlay applique
          une tolérance zéro envers tout contenu présentant ou encourageant la
          nudité non-consentie.
        </li>
        <li>
          <span className="paragraph-s-700">
            Informations personnelles privées :
          </span>{" "}
          Il est interdit de partager du contenu qui contient des informations
          personnelles privées d'autres personnes sans leur consentement.
        </li>
        <li>
          <span className="paragraph-s-700">Suicide et automutilation :</span>{" "}
          Il est interdit de partager du contenu qui prône, encourage ou
          glorifie le suicide ou l'automutilation.
        </li>
        <li>
          <span className="paragraph-s-700">Contenu violent :</span> Il est
          interdit de partager du contenu à caractère menaçant, incitant,
          glorifiant ou exprimant un désir de violence ou de préjudice.
        </li>
        <li>
          <span className="paragraph-s-700">Politique :</span> Il est interdit
          de parler de politique sur TalkPlay.
        </li>
      </ul>

      <h3 className="h3-mobile">Comment signaler ?</h3>
      <p className="paragraph-s-400">
        Vous pouvez signaler toute atteinte à ces Normes Communautaires
        directement via les dispositifs de signalement de l'application
        TalkPlay. L’équipe de TalkPlay étudiera le signalement et prendra ou non
        une décision (avertissement ou sanctions).
      </p>
    </div>
  );
};
