// RoutesWithVideoProvider.tsx

import { Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { VideoProvider } from "../components";
import { useAuth } from "../providers/userAuth";
import {
  HomePage,
  SignIn,
  UserInformation,
  ConnectGames,
  ClashOfClansAuth,
  ClashRoyaleAuth,
  BrawlStarsAuth,
  RiotAuth,
  PostPage,
  ReportPost,
  GameplayPage,
  CreatePost,
  CreateGameplay,
  GamePage,
  GameInfoPage,
  SearchPage,
  CommunitiesPage,
  CombinedFriendsPage,
  FriendsList,
  RequestFriendsList,
  UserPage,
  ReportUser,
  PagesContainer,
  ChooseGame,
  UserPostsPage,
  UserGamesPage,
  PagesContainerSettings,
  PrivacyPolicy,
  TermsOfService,
  CommunityGuidelines,
  UserGameplayPage,
  LostPassword,
  ChangePassword,
  UserGameplayJeuxPage,
  GameplaysByGamePage,
  LandingPage,
} from "../pages";

const HandleStaticFiles = () => {
  if (
    window.location.pathname.match(
      /\.(png|ico|json|jpg|jpeg|svg|webp|css|js|woff|woff2|ttf)$/,
    )
  ) {
    return null;
  }
  return <Navigate to="/" replace />;
};

export const RoutesWithVideoProvider = () => {
  const { user } = useAuth();

  return (
    <VideoProvider>
      <Routes>
        {/* Routes publiques */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/sign" element={<SignIn />} />

        {/* Routes protégées */}
        <Route path="/home" element={<PrivateRoute element={<HomePage />} />} />
        <Route
          path="/inscription"
          element={<PrivateRoute element={<UserInformation />} />}
        />
        <Route
          path="/connectgames"
          element={<PrivateRoute element={<ConnectGames />} />}
        />
        <Route
          path="/connectgames/clashofclans"
          element={<PrivateRoute element={<ClashOfClansAuth />} />}
        />
        <Route
          path="/connectgames/clashroyale"
          element={<PrivateRoute element={<ClashRoyaleAuth />} />}
        />
        <Route
          path="/connectgames/brawlstars"
          element={<PrivateRoute element={<BrawlStarsAuth />} />}
        />
        <Route
          path="/connectgames/riot"
          element={<PrivateRoute element={<RiotAuth />} />}
        />
        <Route
          path="/connectgames/:token"
          element={<PrivateRoute element={<ConnectGames />} />}
        />
        <Route
          path="/talk/:postId"
          element={<PrivateRoute element={<PostPage />} />}
        />
        <Route
          path="/talk/:postId/report"
          element={<PrivateRoute element={<ReportPost />} />}
        />
        <Route
          path="/play/:gameplayId"
          element={<PrivateRoute element={<GameplayPage />} />}
        />
        <Route
          path="/createtalk"
          element={<PrivateRoute element={<CreatePost />} />}
        />
        <Route
          path="/createplay"
          element={<PrivateRoute element={<CreateGameplay />} />}
        />
        <Route
          path="/game/:gameId/:name"
          element={<PrivateRoute element={<GamePage />} />}
        />
        <Route
          path="/game/:gameId/:name/info"
          element={<PrivateRoute element={<GameInfoPage />} />}
        />
        <Route
          path="/search"
          element={<PrivateRoute element={<SearchPage />} />}
        />
        <Route
          path="/community"
          element={<PrivateRoute element={<CommunitiesPage />} />}
        />
        <Route
          path="/managefriends"
          element={<PrivateRoute element={<CombinedFriendsPage />} />}
        />
        <Route
          path="/managefriends/friends"
          element={<PrivateRoute element={<FriendsList />} />}
        />
        <Route
          path="/managefriends/friendsrequests"
          element={<PrivateRoute element={<RequestFriendsList />} />}
        />
        <Route
          path="/user/:username"
          element={<PrivateRoute element={<UserPage />} />}
        />
        <Route
          path="/user/:username/report"
          element={<PrivateRoute element={<ReportUser />} />}
        />
        <Route
          path="/messages/*"
          element={<PrivateRoute element={<PagesContainer />} />}
        />
        <Route
          path="/choosegame"
          element={<PrivateRoute element={<ChooseGame />} />}
        />
        <Route
          path="/user/:username/talks"
          element={<PrivateRoute element={<UserPostsPage />} />}
        />
        <Route
          path="/user/:username/games"
          element={<PrivateRoute element={<UserGamesPage />} />}
        />
        <Route
          path="/user/:username/plays"
          element={<PrivateRoute element={<UserGameplayPage />} />}
        />
        <Route
          path="/user/:username/plays/jeux"
          element={<PrivateRoute element={<UserGameplayJeuxPage />} />}
        />
        <Route
          path="/user/:username/plays/jeux/:gameId"
          element={<PrivateRoute element={<GameplaysByGamePage />} />}
        />
        <Route
          path="/settings/*"
          element={<PrivateRoute element={<PagesContainerSettings />} />}
        />
        <Route path="/legal/privacy" element={<PrivacyPolicy />} />
        <Route path="/legal/terms" element={<TermsOfService />} />
        <Route path="/legal/guidelines" element={<CommunityGuidelines />} />
        <Route path="/forgot-password" element={<LostPassword />} />
        <Route path="/reset-password/:token" element={<ChangePassword />} />
        {/* Redirection pour les routes non correspondantes */}
        <Route path="*" element={<HandleStaticFiles />} />
      </Routes>
    </VideoProvider>
  );
};
