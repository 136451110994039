import React, { useEffect, useState } from "react";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../../providers/userAuth";
import {
  BackButton,
  ChevronIcon,
  SettingFrame,
  TransparentButton,
} from "../../../components";
import { User } from "../../../types";

interface SettingsProps {
  children: React.ReactNode;
}

export const Settings: React.FC<SettingsProps> = ({ children }) => {
  const navigate = useNavigate();
  const { user: authUser, logoutAction } = useAuth();
  const username = authUser?.username;
  const [userData, setUserData] = useState<User>();
  const isWideScreen = useMediaQuery({ minWidth: 745 });
  const location = useLocation();

  useEffect(() => {
    setUserData(authUser);
  }, [authUser]);

  const navigateEditAccount = () => {
    navigate(`/settings/account`);
  };

  const navigateEditProfil = () => {
    navigate(`/settings/editprofil`);
  };

  const navigatePrivacyPage = () => {
    navigate(`/settings/privacy`);
  };

  const discordRedirection = () => {
    window.open(
      "https://discord.gg/z2sWT2BYCU",
      "_blank",
      "noopener,noreferrer",
    );
  };

  const navigateConnectGmames = () => {
    navigate(`/connectgames/`);
  };

  const handleLogout = () => {
    logoutAction();
    navigate(`/sign`);
  };

  return (
    <div
      className={`flex w-full  gap-x-8 max-1156:min-w-[calc(100vw-110px)] max-1157:w-[calc(100vw-110px)] min-1157:w-[calc(100vw-288px)] min-1157:min-w-[calc(100vw-288px)] min-1157:max-w-[992px] min-1280:!min-w-0 max-mobile:!w-full max-mobile:items-center min-745:min-h-[calc(100vh-58px)] min-745:pl-8 ${isWideScreen ? "flex-row" : "flex-col"}`}
    >
      <div className="flex w-full max-w-[600px] flex-col gap-y-6 px-5 pb-[60px] pt-10">
        <div className="min-745:hidden">
          <BackButton />
        </div>
        <div className="flex flex-col items-start gap-y-6 self-stretch">
          <div className="flex items-center gap-x-10 self-stretch">
            <p className="h1-mobile">Paramètres</p>
          </div>
          <div className="flex flex-col gap-y-4 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-6">
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigateEditAccount}
            >
              <div className="flex gap-x-2 self-stretch">
                <SettingFrame noBackground={true} settingIconName="User" />
                <div className="flex flex-col gap-y-1">
                  <p className="nav-menu-500">Accès au compte</p>
                  <p className="label-s-400">Pseudo et mot de passe</p>
                </div>
              </div>
              <ChevronIcon orientation="right" />
            </div>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigateEditProfil}
            >
              <div className="flex gap-x-2 self-stretch">
                <SettingFrame noBackground={true} settingIconName="Edit" />
                <p className="nav-menu-500 hover:underline">
                  Modifier ton profil
                </p>
              </div>
              <ChevronIcon orientation="right" />
            </div>
            <div
              className="flex cursor-pointer items-center justify-between self-stretch"
              onClick={navigatePrivacyPage}
            >
              <div className="flex gap-x-2 self-stretch">
                <SettingFrame noBackground={true} settingIconName="ViewHide" />
                <p className="nav-menu-500 hover:underline">Confidentialité</p>
              </div>
              <ChevronIcon orientation="right" />
            </div>
            <div
              className="flex cursor-pointer items-center self-stretch"
              onClick={discordRedirection}
            >
              <div className="flex gap-x-2 self-stretch">
                <SettingFrame noBackground={true} settingIconName="Help" />
                <p className="nav-menu-500 hover:underline">
                  Aide{" "}
                  <span className="label-s-400">
                    (Redirection vers Discord)
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center gap-x-2"
            onClick={handleLogout}
          >
            <SettingFrame noBackground={true} settingIconName="Disconnect" />
            <p className="nav-menu-500 hover:underline">Se déconnecter</p>
          </div>
          <div className="flex flex-col items-start gap-y-4">
            <p className="paragraph-s-500 self-stretch">
              Enrichis ton expérience TalkPlay en ajoutant plus de jeux de à ton
              compte
            </p>
            <TransparentButton
              label="Ajouter un jeu"
              onClick={navigateConnectGmames}
            />
          </div>
        </div>
      </div>

      {isWideScreen && (
        <div className="relative w-full !max-w-[610px] max-1157:min-w-[calc(100vw-370px-180px)]">
          {children}
        </div>
      )}
    </div>
  );
};
