import { useQuery } from "react-query";
import { Gameplay, PlayerStats, User } from "../../types";
import { fetchPlayerStats } from "../../helpers/fetchGame";
import {
  BackButton,
  SecondaryButton,
  FormatTimeDifference,
  OverlayUnauthenticated,
  AvatarIcon,
  TransparentSecondaryButton,
  SendMessage,
  Loading,
  CommentGameplayCard,
  UserPostGameStat,
  ImagesIcon,
  MediaThumbnail,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../providers/userAuth";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchOneGameplay,
  createCommentGameplay,
  deleteGameplay,
} from "../../helpers/fetchGameplay";
import { useMediaQuery } from "react-responsive";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { formatDate } from "../../utils/dateUtils";
import React from "react";

export const GameplayPage = () => {
  const { gameplayId } = useParams();
  const [user, setUser] = useState<User>();
  const [hasGameAccount, setHasGameAccount] = useState(true);
  const [commentContent, setCommentContent] = useState("");
  const [commentFiles, setCommentFiles] = useState<File[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isDesktop = useMediaQuery({ minWidth: 851 });

  const {
    data: gameplay,
    isLoading,
    isError,
  } = useQuery<Gameplay, Error>(
    ["gameplay", gameplayId],
    fetchOneGameplay(gameplayId ? gameplayId : ""),
  );

  const userId = gameplay?.user.id;
  const gameId = gameplay?.game.id;

  useEffect(() => {
    setUser(auth.user);
  }, [auth]);

  const {
    data: playerStats,
    isLoading: statsLoading,
    isError: statsError,
  } = useQuery<PlayerStats, Error>(
    ["playerStats", userId, gameId],
    () => fetchPlayerStats(String(gameId), String(userId))(),
    {
      onError: (error: Error & { response?: { status: number } }) => {
        if (error.response?.status === 404) {
          setHasGameAccount(false);
        }
      },
      enabled: !!userId && !!gameId,
    },
  );

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && commentFiles.length + files.length > 4) {
      toast.error("Veuillez mettre au maximum 4 médias", {
        position: "top-center",
        autoClose: 3000,
      });
    } else if (files) {
      setCommentFiles([...commentFiles, ...Array.from(files)]);
    }
  };

  const handleDeleteClick = (index: number) => {
    const newCommentFiles = [...commentFiles];
    newCommentFiles.splice(index, 1);
    setCommentFiles(newCommentFiles);
  };

  const handleSendComment = async () => {
    if (commentContent.trim() === "") {
      toast.error("Le contenu du commentaire ne peut pas être vide");
      return;
    }
    try {
      await createCommentGameplay(gameplayId ?? "", commentContent);
      toast.success("Commentaire créé avec succès !");
      setCommentContent("");
      setCommentFiles([]);
    } catch (error) {
      console.error(error);
      toast.error("Échec de la création du commentaire");
    }
  };

  const handleDeleteGameplay = async () => {
    try {
      await deleteGameplay(gameplayId ?? "");
      navigate("/");
    } catch (error) {
      console.error(error);
      toast.error("Échec de la suppression du gameplay");
    }
  };

  const navigatePlayerPage = (username: string) => {
    navigate(`/user/${encodeURIComponent(username)}`);
  };

  const navigateGamePage = () => {
    navigate(`/game/${gameplay.game.id}/${gameplay.game.name}`);
  };

  if (!gameplay) {
    return <div>No gameplay found</div>;
  }

  if (!auth.user) {
    return <OverlayUnauthenticated />;
  }

  const latestComment =
    gameplay.comments.length > 0
      ? gameplay.comments.reduce((prev, current) => {
          return new Date(prev.created_at) > new Date(current.created_at)
            ? prev
            : current;
        })
      : null;

  const commentCountLabel =
    gameplay.comments.length === 0
      ? "Aucun commentaire"
      : gameplay.comments.length === 1
        ? "1 commentaire"
        : `${gameplay.comments.length} commentaires`;

  return (
    <div className="flex min-h-screen w-full flex-col items-center max-mobile:pt-14 min-851:pt-4">
      {isLoading && <Loading />}
      {isError && <div className="h1-mobile">{isError}</div>}

      <div className="w-full max-w-[1200px] px-5">
        <div className="relative mb-4 flex w-full items-center justify-center pt-2">
          {/* Back Button */}
          <div className="absolute left-0">
            <BackButton />
          </div>

          {/* Game Name and Logo */}
          {gameplay.game && (
            <div className="flex items-center gap-x-2">
              <p
                className="truncate-one-line h3-mobile cursor-pointer hover:underline max-500:hidden"
                onClick={navigateGamePage}
              >
                {gameplay.game.name}
              </p>
              <div
                className="game-thumbnail-s cursor-pointer border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)] hover:border-[rgba(255,255,255,0.6)]"
                onClick={navigateGamePage}
              >
                <div
                  className="z-1 flex h-full w-full items-start rounded-[4px]"
                  style={{
                    background: `url(${gameplay.game.logo}) 50% / cover no-repeat`,
                  }}
                ></div>
              </div>
            </div>
          )}

          {/* Follow Button */}
          {auth.user.id !== gameplay.user.id && (
            <div className="absolute right-0 min-745:hidden">
              <SecondaryButton
                userId={gameplay.user.id}
                initialFollow={false}
                actionType="follow"
              />
            </div>
          )}
        </div>

        <div className="mb-3 w-full">
          <video
            controls
            src={gameplay.file}
            className="max-h-[500px] w-full rounded-lg object-cover shadow-lg"
          />
        </div>

        <div className="mb-6 flex w-full flex-col gap-x-4">
          <div className="flex-grow pb-1">
            <p className="h2-mobile">{gameplay.name}</p>
          </div>
          <div className="relative flex items-center gap-x-3">
            <AvatarIcon
              logo={gameplay.user.logo}
              className="avatar-s cursor-pointer"
              onClick={() => navigatePlayerPage(gameplay.user.username)}
            />
            {gameplay.game ? (
              <div
                className="game-thumbnail-xxs   absolute bottom-[-1px] left-[28px] cursor-pointer border-[0.5px] border-[rgba(198,182,213,0.50)] shadow-[-1px_-1px_4px_rgba(0,0,0,0.25)] hover:border-[rgba(255,255,255,0.6)]"
                onClick={navigateGamePage}
              >
                <div
                  className="z-1 flex h-[22px] w-[22px] items-start rounded-[4px]"
                  style={{
                    background: `url(${gameplay.game.logo}) 50% / cover no-repeat`,
                  }}
                ></div>
              </div>
            ) : null}
            <div className="flex flex-col">
              <div className="flex items-center gap-x-2">
                <p
                  className="h4-mobile cursor-pointer whitespace-nowrap opacity-90 hover:text-[#F78888]"
                  onClick={() => navigatePlayerPage(gameplay.user.username)}
                >
                  {gameplay.user.pseudo || `@${gameplay.user.username}`}
                </p>
                {gameplay.taggedUsers && gameplay.taggedUsers.length > 0 && (
                  <div className="truncate-one-line flex w-full items-center gap-x-2">
                    <p className="label-s-400">avec</p>
                    {gameplay.taggedUsers
                      .filter(
                        (taggedUser) => taggedUser.id !== gameplay.user.id,
                      )
                      .map((taggedUser, index, array) => (
                        <React.Fragment key={taggedUser.id}>
                          <p
                            className="label-s-400 cursor-pointer whitespace-nowrap opacity-90 hover:text-[#F78888]"
                            onClick={() =>
                              navigatePlayerPage(taggedUser.username)
                            }
                          >
                            {taggedUser.pseudo || `@${taggedUser.username}`}
                          </p>
                          {index < array.length - 1 && (
                            <span className="text-gray-400">+</span>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                )}
              </div>
              {gameplay.game && (
                <p
                  className="truncate-one-line label-s-400 cursor-pointer opacity-50 hover:underline"
                  onClick={navigateGamePage}
                >
                  {gameplay.game.name}
                </p>
              )}
              <p className="label-s-400 whitespace-nowrap opacity-40">
                {formatDate(gameplay.created_at)}
              </p>
            </div>
          </div>
        </div>

        {/* Comment Input Box */}
        <div className="mb-5 flex w-full flex-col gap-8 min-851:flex-row">
          <div className="flex-1">
            <div className="mb-2 flex flex-col items-center">
              <div
                className={`relative z-[5] flex w-full flex-col gap-y-2 rounded-2xl border border-[0.5px] border-[rgba(198,182,213,0.15)] bg-[rgba(245,235,255,0.10)] px-4 py-2 transition-all duration-300 ${
                  isFocused
                    ? "justify-centers h-auto ring-1 ring-inset ring-[rgba(198,182,213,0.35)] hover:border-[rgba(198,182,213,0.55)]"
                    : "h-12 justify-center"
                }`}
              >
                <div className="flex h-12 items-start">
                  <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "comment-textbox",
                      name: "comment-textbox",
                      type: "text",
                      placeholder: "Rédige ton commentaire",
                    }}
                    value={commentContent}
                    onChange={(value: string) => setCommentContent(value)}
                    onBlur={() => setIsFocused(true)}
                    onFocus={() => setIsFocused(true)}
                    validationOption={{
                      name: "Comment",
                      check: false,
                      required: false,
                    }}
                    classNameInput="label-m-400 flex-grow !border-none !bg-transparent !outline-none focus:opacity-100"
                    classNameWrapper="w-full !rounded-md outline-none"
                    classNameContainer="label-m-400 bg-transparent !p-0 !rounded-md"
                  />
                </div>
                {commentFiles.length > 0 && (
                  <div className="flex gap-x-2 overflow-x-auto p-2">
                    {commentFiles.slice(0, 4).map((file, index) => (
                      <MediaThumbnail
                        isVideo={file.type.includes("video")}
                        key={index}
                        banner={URL.createObjectURL(file)}
                        size="m"
                        showSelectionIndicator={true}
                        onClick={() => handleDeleteClick(index)}
                      />
                    ))}
                  </div>
                )}
                {isFocused && (
                  <div className="flex w-full items-end justify-between">
                    <div onClick={handleFileInputClick}>
                      <ImagesIcon />
                    </div>
                    <input
                      type="file"
                      accept="image/*,video/*"
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      multiple
                      style={{ display: "none" }}
                    />
                    <SendMessage onClick={handleSendComment} />
                  </div>
                )}
              </div>
            </div>
            <div className="h3-mobile mb-4">{commentCountLabel}</div>
            {gameplay.comments.map((comment) => (
              <CommentGameplayCard key={comment.id} comment={comment} />
            ))}
          </div>

          {/* Player Stats */}
          {playerStats && (
            <div className="flex w-full flex-col min-851:w-[300px]">
              <UserPostGameStat
                post={gameplay}
                playerStats={playerStats}
                hasGameAccount={hasGameAccount}
              />
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
