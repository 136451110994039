// AppLayout.tsx

import { useEffect } from "react";
import {
  MainHeader,
  MainHeaderMobile,
  Sidebar,
  SidebarInscription,
  OverlayProvider,
  ManageWidgetDiscussions,
  VideoProvider,
} from "./components/";
import { useLocation, useNavigate } from "react-router-dom";
import { useRouteOrigin } from "./contexts/RouteOriginContext";
import { useMediaQuery } from "react-responsive";
import { RoutesWithVideoProvider } from "./contexts/RoutesWithVideoProvider";

const noSidebarRoutes = [
  "/sign",
  "/confirmationregister",
  "/inscription",
  "/changepassword",
  "/changepasswordconfirmation",
  "/lostpassword",
  "/mailconfirmation",
  "/lostpassword/confirmation",
  "/legal/privacy",
  "/legal/terms",
  "/legal/guidelines",
  "/reset-password/:token",
  "/forgot-password",
];

const registrationRoutes = ["/inscription", "/connectgames"];

const mobileHeaderRoutes = [
  "/home",
  "/events",
  "/community",
  "/search",
  "/managefriends",
  "/messages",
  "/talk/*",
  "/play/*",
];

const matchRoute = (path: string, pattern: string) => {
  const regex = new RegExp("^" + pattern.replace(/:[^\s/]+/g, "([^/]+)") + "$");
  return regex.test(path);
};

const getMobileHeaderLabel = (pathname: string) => {
  if (pathname === "/messages") return "Discussions";
  if (pathname === "/managefriends") return "Gestion des amis";
  return null;
};

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fromRegistration, setFromRegistration } = useRouteOrigin();
  const isWideScreen = useMediaQuery({ query: "(min-width: 745px)" });
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 745px) and (max-width: 1156px)",
  });
  const isMidScreen = useMediaQuery({ query: "(min-width: 950px)" });

  const isNoSidebarRoute =
    location.pathname === "/" ||
    fromRegistration ||
    noSidebarRoutes.some((route) => matchRoute(location.pathname, route)) ||
    location.pathname.startsWith("/connectgames");

  const showSidebar =
    isWideScreen && !isNoSidebarRoute && location.pathname !== "/legal/privacy";

  const showHeader = showSidebar || !isNoSidebarRoute;

  const showInputSearch = ["/search"].includes(location.pathname);

  const isMessageRoute = location.pathname.startsWith("/messages");

  const mainGridClass = showSidebar
    ? "flex grid min-745:grid-cols-[110px_1fr] min-computer:grid-cols-[288px_1fr]"
    : "flex min-w-[100svw]";

  const secondMainGridClass = showSidebar
    ? `order-2 flex h-auto min-h-[100vh] w-[100%] pt-[58px] ${
        isMessageRoute ? "" : "min-745:justify-center"
      }`
    : "order-2 flex w-[100%] justify-center";

  const showMobileHeader =
    !isWideScreen &&
    mobileHeaderRoutes.some((route) =>
      route.includes("*")
        ? location.pathname.startsWith(route.replace("/*", ""))
        : matchRoute(location.pathname, route),
    );

  const mobileHeaderLabel = getMobileHeaderLabel(location.pathname);

  const showManageWidgetDiscussions =
    !location.pathname.startsWith("/messages") && !noSidebarRoutes;

  useEffect(() => {
    if (
      isMidScreen &&
      (location.pathname === "/managefriends/friends" ||
        location.pathname === "/managefriends/friendsrequests")
    ) {
      navigate("/managefriends");
    }
  }, [isWideScreen, location.pathname, navigate]);

  useEffect(() => {
    const isRegistrationRoute = registrationRoutes.some((route) =>
      location.pathname.startsWith(route),
    );

    if (!isRegistrationRoute) {
      setFromRegistration(false);
    }
  }, [location.pathname, setFromRegistration]);

  return (
    <OverlayProvider>
      <VideoProvider>
        <div
          className={`${
            isWideScreen && showSidebar ? "min-745:justify-center" : ""
          } flex overflow-x-hidden`}
          style={{
            background: "linear-gradient(0deg, #280C31 61.19%, #140029 100%)",
          }}
        >
          <div
            className={`${
              isWideScreen ? "" : "w-full"
            } relative w-full min-computer:w-[1280px]`}
          >
            {showHeader && isWideScreen && (
              <MainHeader search={showInputSearch} />
            )}
            {showMobileHeader && (
              <MainHeaderMobile label={mobileHeaderLabel || undefined} />
            )}
            {showManageWidgetDiscussions && <ManageWidgetDiscussions />}
            <div className={mainGridClass}>
              <div className={secondMainGridClass}>
                <RoutesWithVideoProvider />
              </div>
              {showSidebar ? (
                <Sidebar size={isMediumScreen ? "small" : "normal"} />
              ) : (
                <SidebarInscription />
              )}
            </div>
          </div>
        </div>
      </VideoProvider>
    </OverlayProvider>
  );
};

export default AppLayout;
