import {
  SubtitleNavigation,
  OverlayUnauthenticated,
  BottomBar,
  InputSearch,
  GameCoverColumn,
  GameDetailCard,
  Loading,
  ButtonSimple,
} from "../../components";
import { useEffect, useState } from "react";
import { useAuth } from "../../providers/userAuth";
import { User, Game } from "../../types";
import { fetchAllGames } from "../../helpers/fetchGame";
import { useNavigate } from "react-router-dom";

const navigationButtons = [
  { title: "Posts", path: `/` },
  { title: "Communautés", path: `/community` },
];

export const CommunitiesPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [games, setGames] = useState<Game[]>([]);
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useState<Game[]>([]);
  const [filteredGames, setFilteredGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setUser(auth.user);

    const fetchGames = async () => {
      try {
        const fetchedGames = await fetchAllGames();
        setGames(fetchedGames);
        const userGames = fetchedGames.filter((game) => game.hasGame);
        setRecentlyPlayedGames(userGames.slice(0, 3)); // Display first 3 games the user has
        setFilteredGames(fetchedGames);
      } catch (error) {
        setError("Erreur lors de la récupération des jeux.");
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [auth]);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredGames(games);
    } else {
      const filtered = games.filter((game) =>
        game.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredGames(filtered);
    }
  }, [searchTerm, games]);

  const navigateConnectGames = () => {
    navigate(`/connectgames/`);
  };

  return (
    <div className="flex min-h-screen w-full flex-col max-mobile:pt-[58px] min-745:min-h-[calc(100svh-58px)] min-851:gap-y-6 min-851:pl-8 min-851:pr-5 min-851:pt-8">
      <p className="h1-mobile max-tablet:hidden">Communautés</p>
      <div className="flex w-full justify-center min-851:hidden">
        <div className="flex max-mobile:max-w-[610px] max-mobile:px-5 min-851:items-center min-851:justify-center">
          <SubtitleNavigation
            buttons={navigationButtons}
            overflowWidth="100%"
          />
        </div>
      </div>
      <div className="flex items-start gap-x-8 max-tablet:px-5">
        <div className="flex w-full flex-col gap-y-4 pb-10 max-tablet:max-w-[610px] min-851:max-w-[560px]">
          <InputSearch
            placeholder="Rechercher une communauté"
            showLogoSection={false}
            searchQuery={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {loading && <Loading />}
          {error && <div className="h1-mobile">{error}</div>}
          <div className="flex w-full flex-col items-start gap-y-4 self-stretch min-851:hidden">
            <p className="h2-mobile">Récemment joués</p>
            <div className="styled-scrollbars flex w-[100%] flex-grow items-start gap-x-2 overflow-x-auto">
              {recentlyPlayedGames.length > 0 ? (
                recentlyPlayedGames.map((game) => (
                  <GameCoverColumn
                    key={game.id}
                    banner={game.logo}
                    name={game.name}
                    gameId={game.id}
                    size="min-w-[140px] cursor-pointer"
                  />
                ))
              ) : (
                <ButtonSimple
                  label="Ajouter des jeux"
                  plusFonction={true}
                  onClick={navigateConnectGames}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col items-start gap-y-4 self-stretch pt-5 min-851:min-w-[380px]">
            <p className="h2-mobile min-851:hidden">Communauté populaires</p>
            <div className="flex flex-wrap items-start max-tablet:gap-2 min-851:gap-6">
              {filteredGames.map((game) => (
                <GameCoverColumn
                  key={game.id}
                  banner={game.logo}
                  name={game.name}
                  gameId={game.id}
                  size="max-tablet:w-[140px] min-851:w-[160px] cursor-pointer transition-transform transform hover:scale-105 hover:shadow-lg"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full max-w-[370px] flex-col items-start  gap-y-8 max-tablet:hidden">
          <div
            className="flex w-full max-w-[370px] flex-col items-start justify-center gap-y-6 rounded-xl p-5 outline outline-1 outline-[rgba(255,255,255,0.20)]"
            style={{
              background:
                "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)",
            }}
          >
            <div className="flex w-full flex-col gap-y-4 self-stretch">
              <p className="h2-mobile">Récemment joués</p>
              <div className="flex w-full flex-col">
                {recentlyPlayedGames.length > 0 ? (
                  recentlyPlayedGames.map((game) => (
                    <div className="rounded-lg px-2 py-1 hover:bg-[rgba(245,235,255,0.20)]">
                      <GameDetailCard
                        key={game.id}
                        gameId={game.id}
                        card={game.card}
                        name={game.name}
                        studioName={game.studios[0].name}
                        platforms={game.platforms.map(
                          (platform) => platform.name,
                        )} // passing an array of platform names
                        // tpPlayers={4873}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    <p className="h4-mobile">
                      Vous ne possédez aucun jeu, ajoutez-en !
                    </p>
                    <ButtonSimple
                      label="Ajouter des jeux"
                      plusFonction={true}
                      onClick={navigateConnectGames}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div
            className="flex w-full max-w-[370px] flex-col items-start justify-center gap-y-6 rounded-xl p-5 max-tablet:hidden"
            style={{
              background:
                "linear-gradient(180deg, rgba(245, 235, 255, 0.10) 0%, rgba(224, 195, 233, 0.10) 100%)",
            }}
          >
            <div className="flex flex-col items-start gap-y-4 self-stretch">
              <p className="h2-mobile">Activités des communautés</p>
              <div className="flex flex-col items-start gap-y-2 self-stretch">
                Plein de posts
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {!user && <OverlayUnauthenticated />}
      <BottomBar />
    </div>
  );
};
