import { useNavigate } from "react-router-dom";
import {
  CloseFillIcon,
  InviteFriends,
  LogoBeta,
  SettingFrame,
  SecondaryButton,
} from "..";
import { useState, useEffect } from "react";
import { useAuth } from "../../providers/userAuth";
import { User } from "../../types";
import { CSSTransition } from "react-transition-group";

interface SidebarMobileProps {
  onClose: () => void;
  isOpen: boolean;
}

export const SidebarMobile: React.FC<SidebarMobileProps> = ({
  onClose,
  isOpen,
}) => {
  const navigate = useNavigate();
  const { user: authUser, logoutAction } = useAuth();
  const username = authUser?.username;
  const [userData, setUserData] = useState<User>();

  useEffect(() => {
    setUserData(authUser);
  }, [authUser]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const encodeUsername = (username: string) => {
    return encodeURIComponent(
      username.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    );
  };

  const navigateToUserPage = () => {
    navigate(`/user/${encodeUsername(username)}`);
    onClose();
  };

  const navigateMessagePage = () => {
    navigate(`/messages`);
    onClose();
  };

  const navigateCommunityPage = () => {
    navigate(`/community`);
    onClose();
  };

  const navigateTerms = () => {
    navigate(`/legal/terms`);
    onClose();
  };

  const navigateGuidelines = () => {
    navigate(`/legal/guidelines`);
    onClose();
  };

  const navigatePrivacy = () => {
    navigate(`/legal/privacy`);
    onClose();
  };

  const navigateChooseGame = () => {
    navigate(`/connectgames`);
    onClose();
  };

  const navigateUserSettings = () => {
    navigate(`/settings`);
    onClose();
  };

  const navigateTosign = () => {
    navigate(`/sign`);
    onClose();
  };

  const navigateToInscription = () => {
    navigate(`/inscription`);
    onClose();
  };

  const handleLogout = () => {
    logoutAction();
    navigate(`/sign`);
    onClose();
  };

  return (
    <CSSTransition in={isOpen} timeout={300} classNames="sidebar" unmountOnExit>
      <div className="fixed inset-0 z-[1000] flex">
        <div
          className="fixed inset-0 bg-black opacity-50"
          onClick={onClose}
        ></div>
        <div
          className={`relative z-50 flex w-[70%] transform-gpu justify-center bg-[#140029] pb-10 transition-transform duration-300 ease-in-out min-745:hidden ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="z-30 flex w-full max-w-[600px] flex-col items-center justify-between gap-y-8 px-5 pt-5">
            <div className="flex flex-col items-start gap-y-8 self-stretch">
              <div className="flex items-center justify-between gap-x-10 self-stretch">
                <LogoBeta />
                <CloseFillIcon onClick={onClose} />
              </div>
              {!authUser ? (
                <>
                  <div className="flex flex-col gap-y-6 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-[30px]">
                    <p className="nav-menu-500 text-center">
                      Vous n'êtes pas connecté. Veuillez vous inscrire ou vous
                      connecter.
                    </p>
                    <SecondaryButton
                      label="Connexion"
                      transparent={true}
                      onClick={navigateTosign}
                    />
                    <SecondaryButton
                      label="Inscription"
                      onClick={navigateToInscription}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-y-6 self-stretch border-b border-b-[rgba(255,255,255,0.25)] pb-[30px]">
                    <div
                      className="flex cursor-pointer items-center gap-x-2 self-stretch"
                      onClick={navigateToUserPage}
                    >
                      <SettingFrame settingIconName="User" />
                      <p className="nav-menu-500">Profil</p>
                    </div>
                    <div
                      className="flex cursor-pointer items-center gap-x-2 self-stretch"
                      onClick={navigateMessagePage}
                    >
                      <SettingFrame settingIconName="Message" />
                      <p className="nav-menu-500">Messagerie</p>
                    </div>
                    <div
                      className="flex cursor-pointer items-center gap-x-2 self-stretch"
                      onClick={navigateCommunityPage}
                    >
                      <SettingFrame settingIconName="Community" />
                      <p className="nav-menu-500">Communautés</p>
                    </div>
                    <div
                      className="flex cursor-pointer items-center gap-x-2 self-stretch"
                      onClick={navigateChooseGame}
                    >
                      <SettingFrame settingIconName="Download" />
                      <p className="nav-menu-500">Ajouter des jeux</p>
                    </div>
                  </div>
                  <div
                    className="flex cursor-pointer items-center gap-x-2 self-stretch"
                    onClick={navigateUserSettings}
                  >
                    <SettingFrame settingIconName="Cogwheel" />
                    <p className="nav-menu-500">Paramètres</p>
                  </div>
                </>
              )}
              {authUser && (
                <div className="flex w-full flex-col items-center gap-y-8">
                  <InviteFriends classname="max-400:p-2" />
                  <div
                    className="flex w-full cursor-pointer items-center gap-x-2 "
                    onClick={handleLogout}
                  >
                    <SettingFrame settingIconName="Disconnect" />
                    <p className="nav-menu-500">Se déconnecter</p>
                  </div>
                </div>
              )}
            </div>
            <div className="flex w-full flex-col items-center gap-y-1">
              <div className="flex w-full flex-wrap content-start items-start justify-center gap-x-2 gap-y-1">
                <p
                  className="label-s-400 cursor-pointer opacity-50 hover:underline hover:opacity-100"
                  onClick={navigateTerms}
                >
                  Termes du service
                </p>
                <p
                  className="label-s-400 cursor-pointer opacity-50 hover:underline hover:opacity-100"
                  onClick={navigatePrivacy}
                >
                  Politique de confidentialité
                </p>
                <p
                  className="label-s-400 cursor-pointer opacity-50 hover:underline hover:opacity-100"
                  onClick={navigateGuidelines}
                >
                  Normes communautaires
                </p>
              </div>
              <p className="label-s-400 opacity-50">
                TalkPlay © 2024. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
